import { Injectable, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HomepageService } from '../homepage/homepage.service';
import { AuthService } from '../auth/auth.service';
import { CampaignService } from '../campaign/campaign.service';
import { EventsService } from '../events/events.service';
import {
  CommonDraftFundraiserComponent
} from '../../components/common-draft-fundraiser/common-draft-fundraiser.component';
import { CookieService } from './../cookie/cookie.service';
import { StorageService } from './../storage/storage.service';
import { UtilService } from './../util/util.service';
import { VariablesService } from './../variables/variables.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { IUser } from '../../models';
import { ApiService } from '../api/api.service';
import { StartAFrUrls } from '../../models/start-fr-urls';
import { MatBottomSheet } from '@angular/material/bottom-sheet';


declare const clevertap;

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  public response;
  public userData;
  isLoginPopUpOpen;
  isLoggedInSub: Subscription;
  isUserLoggedIn = false;
  public postLoginCB = null;
  private user_type;
  public socialSignInType = new BehaviorSubject<any>(null);
  public socialLoginToken = new BehaviorSubject<any>(null);
  public loginInitiatedFrom: string;
  public isLeadGenerated: boolean;
  public otp: any;
  public startFrType = ''; // medical/ngo/others
  public neokred = false;
  public draftData;
  startAFrUrls = StartAFrUrls;
  noRedirectAfterLogin = false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private utilService: UtilService,
    private events: EventsService,
    private actRoute: ActivatedRoute,
    private variablesService: VariablesService,
    private router: Router,
    private dialog: MatDialog,
    private sheet: MatBottomSheet,
    private homepageService: HomepageService,
    private campaignService: CampaignService,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    public http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService
  ) {
    this.isLoggedInSub = this.utilService.isLoggedIn.subscribe(
      (res) => {
        if (res === true && this.utilService.isBrowser) {
          this.isUserLoggedIn = true;
          // Pages on which draft popup will not show
          // const pages = 'home|browse_fundraiser';
          const validPages = ['/crowdfunding/fundraisers', '/'];
          //  showDraft: Flag to indicate whether to show/hide draft.
          const showDraft = validPages.includes(window.location.pathname);
          if (showDraft) {
            this.checkForDraft();
          }
        } else {
          this.isUserLoggedIn = false;
        }
      }
    );
  }

  signupFOS(data) {
    return new Promise((resolve, reject) => {
      try {
        const formValues = data.formValues;
        this.startFrType = formValues.startFrType ? formValues.startFrType : 'medical';
        const payload = this.createSignupPayload(formValues);
        this.authService.register(payload).pipe(take(1)).subscribe(
          async (res: any) => {
            this.variablesService.loginType = 'signup';
            this.variablesService.loginMethod = 'form';
            this.response = res;
            if (!this.response.error) {
              this.storageService.addSessionData('sac-purpose-selected', { 'purpose': this.startFrType });
              const dockFrData = this.storageService.getFromSession('dockFrData');
              if ((this.isLeadGenerated !== true || !dockFrData)) {
                this.generateLead(formValues).then(() => {
                  this.afterLoginFOS(res);
                  resolve(true);
                });
              } else {
                this.afterLoginFOS(res);
              }
            }
          },
          (err) => {
            if (typeof window !== 'undefined' && err.error && err.error.message === 'The email id has already been taken.') {
              const signUpPage = ['new/crowdfunding'];
              const isSignUpPage = signUpPage.some(page => window.location.pathname.includes(page));
              if (isSignUpPage) {
                const matConfig: MatSnackBarConfig = {
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
                  panelClass: ['snackbar-success'],
                  duration: 10000
                };
                this.snackBar.open('Your are already registered with us, Please login', 'LOGIN', matConfig).onAction().subscribe(() => {
                  this.storageService.checkFromSession('signup-email', payload.email_id);
                  this.router.navigate(['/new/signin'], { queryParams: { 'redirect_uri': this.router.url } });
                });
              }
            } else {
              this.utilService.openSnackBar(err.error.message, 'error');
            }
            reject(err);
          }
        );
      } catch (error) {
        reject(false);
      }
    });
  }

  signup(data, allowMultipleLeads?, otpStep1?) {
    return new Promise((resolve, reject) => {
      try {
        const formValues = data.formValues;
        this.startFrType = formValues.startFrType ? formValues.startFrType : 'medical';
        const payload = this.createSignupPayload(formValues);
        payload['g-recaptcha-response'] = formValues.captcha,
          this.authService.register(payload, 'hideError', otpStep1).pipe(take(1)).subscribe(
            async (res: any) => {
              this.variablesService.loginType = 'signup';
              this.variablesService.loginMethod = 'form';
              this.response = res;
              if (!this.response.error) {
                await this.sacAfterRegister(res, formValues, otpStep1, allowMultipleLeads);
                resolve('');
              }
            },
            async (err) => {
              if (typeof window !== 'undefined' && err.error && err.error.message === 'The email id has already been taken.') {
                const signUpPage = ['new/crowdfunding'];
                const isSignUpPage = signUpPage.some(page => window.location.pathname.includes(page));
                if (isSignUpPage) {
                  const data = {
                    phone: payload?.phone_1,
                    extension: payload?.extension,
                    firebase_response: payload?.firebase_response,
                    showError: 'false'
                  };
                  const loginOTOresp = await new Promise(resolve1 => {
                    this.authService.loginOTPVerify(data).subscribe({
                      next: async (resp) => {
                        await this.sacAfterRegister(resp, formValues, otpStep1, allowMultipleLeads);
                        resolve1('');
                      },
                      error: (err) => {
                        if (err.error.message !== 'User Not Found') {
                          this.utilService.openSnackBar(err?.error?.message, 'error');
                        }
                        reject(err);
                      }
                    });
                  });
                  resolve(loginOTOresp);

                  // const matConfig: MatSnackBarConfig = {
                  //   verticalPosition: 'top',
                  //   horizontalPosition: 'center',
                  //   panelClass: ['snackbar-success'],
                  //   duration: 10000
                  // };
                  // this.snackBar.open('You are already registered with us, Please login', 'LOGIN', matConfig).onAction().subscribe(() => {
                  //   this.storageService.checkFromSession('signup-email', payload.email_id);
                  //   this.router.navigate(['/new/signin'], { queryParams: { 'redirect_uri': this.router.url } });
                  // });
                }
              } else {
                this.utilService.openSnackBar(err.error.message, 'error');
              }
              reject(err);
            }
          );
      } catch (error) {
        reject(false);
      }
    });
  }

  sacAfterRegister(response, formValues, otpStep1, allowMultipleLeads?) {
    return new Promise((resolve) => {
      this.variablesService.loginType = 'signup';
      this.startFrType = formValues.startFrType || this.startFrType || 'medical';
      this.storageService.addSessionData('sac-purpose-selected', { 'purpose': this.startFrType });
      const dockFrData = this.storageService.getFromSession('dockFrData');
      if ((this.isLeadGenerated !== true || !dockFrData)) {
        this.generateLead(formValues, allowMultipleLeads).then(() => {
          if (!otpStep1) {
            this.afterLogin(response);
          }
          resolve('');
        });
      } else {
        if (!otpStep1) {
          this.afterLogin(response);
        }
        resolve('');
      }
    });
  }

  createSignupPayload(formValues) {
    const payload = {
      'email_id': formValues.email,
      'password': formValues.password,
      'user_type': 'individual',
      'registration_source': formValues?.registration_source || 'direct',
      'full_name': formValues.name,
      'phone_1': formValues.mobile.replace(/\s/g, ''),
      'extension': formValues.extn,
      'is_subscribed': '1',
      // 'g-recaptcha-response': formValues.captcha,
      'invisible_recaptcha': true,
      'accessToken': formValues.accessToken,
      'firebase_response': formValues?.firebase_response
    };
    return payload;
  }

  verifyEmail(payload) {
    const baseUrl: string = environment.APP.BASE_URL;
    const httpOptions: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(baseUrl + environment.APP.API_URLS.CHECK_EXISTING_EMAIL, payload, httpOptions);

  }

  generateLeadOtp(data) {
    return new Promise((resolve, reject) => {
      try {
        const formValues = data.formValues;
        this.startFrType = formValues.startFrType ? formValues.startFrType : 'medical';
        this.variablesService.loginType = 'signup';
        this.generateLead(formValues).then((res) => {
          if (res.error) {
            reject(res.err);
          } else {
            const matConfig: MatSnackBarConfig = {
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['snackbar-success'],
              duration: 10000
            };
            if (res.data.user_exist) {
              this.snackBar.open('You are already registered with us, Please login', 'LOGIN', matConfig).onAction().subscribe(() => {
                this.storageService.checkFromSession('signup-email', formValues.email);
                this.router.navigate(['/new/signin'], { queryParams: { 'redirect_uri': this.router.url } });
              });
              reject('already registered with us');
            } else {
              resolve(res.data);
            }
          }
        });
      } catch (error) {
        reject(false);
      }
    });
  }
  /**Login automatically after register */
  login(formValues) {
    return new Promise((resolve, reject) => {
      try {
        const payload = {
          'email_id': formValues.email_id,
          'password': formValues.password,
          'g-recaptcha-response': formValues['g-recaptcha-response']
        };
        this.authService.login(payload).subscribe(
          (res) => {
            this.variablesService.loginType = 'signin';
            this.variablesService.loginMethod = 'form';
            this.afterLogin(res);
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (error) {
        reject(false);
      }
    });
  }

  /**
   * After login hit
   * @param response response got from facbook
   */
  async afterLogin(response, value?, infoData?) {
    this.response = response;
    this.response.data.user['isLoggedIn'] = true;
    this.utilService.setUser(response.data);
    this.user_type = response.data.type;
    this.getUserData(infoData).then(() => this.afterLoginRedirects(value));
  }

  /**
 * After fos login hit
 * @param response response got login
 */
  async afterLoginFOS(response) {
    this.response = response;
    this.utilService.setUser(response.data);
    this.user_type = response.data.type;
    await this.getUserData();
    this.authService.userLogin({ user: response.data, token: response.data.token });
    if (this.variablesService.isFOS) {
      response.data.token = this.variablesService.adminToken;
      this.utilService.setUser(response.data);
    }
    setTimeout(() => {
      this.utilService.router.navigate(['/new/crowdfunding/medical'], {queryParamsHandling: 'merge'});
    }, 1000);
  }

  /**Push data after signup */
  async pushDataSignup(res, infoData?) {
    const data = res.data;
    const browser = this.utilService.detectBrowser();
    // CleaverTap signup event
    const claverTapData = {
      'mode': 'Native' || 'Otp',
      'Device Type': this.utilService.isMobile() ? 'Mobile' : 'Desktop',
      'Page URL': this.document?.location?.href || '',
      'Session ID': data.token,
      'IP Address': '',
      'Browser': browser,
      'Email': data.user.email_id,
      'User Type': 'individual',
      'Signup Via': this.variablesService.loginMethod
    };
    this.events.claverTapPush('SignUp', claverTapData);
    this.gaEventpush('signup');
    await this.trackSystemEvent(infoData);
  }

  private gaEventpush(type: string) {
    const gtmData = {
      event: type,
      event_category: type,
      event_action: 'individual',
      event_label: 'Success'
    };
    this.events.gtmPush(gtmData);
  }

  async pushDataLogin(user, infoData?) {
    const data = {
      Site: {
        Identity: user.id,
        Name: user.full_name,
        Email: user.email,
        Phone: user.phone_1 ? ('+91' + user.phone_1.replace(/\s/g, '')) : '',
        Entity_Type: user.entity_type,
        Login_Via: this.variablesService.loginMethod,
        country_code: user?.extension || ''
      }
    };
    if (user.gender && user.gender !== 'NA') {
      data.Site['Gender'] = user.gender;
    }
    this.events.claverProfilePush(data);
    this.gaEventpush('signin');
    await this.trackSystemEvent(infoData);
  }

  private trackSystemEvent(infoData?) {
    return new Promise(async (resolve) => {
      try {
        const systemEvent = {
          eventName: this.variablesService.loginType, // signup / signin
          event_type: this.variablesService.loginType, // start a fundraiser/ signin
          page_name: this.variablesService.pageName || 'home',
          info_1: infoData?.info_1 || 'type',
          info_2: this.variablesService.loginMethod || 'form'  // fb/google/form
        };
        await this.events.sendSystemEvent(systemEvent).subscribe(
          () => {
            resolve('');
          },
          () => {
            resolve('');
          }
        );
      } catch (error) {
      }
    });
  }

  /**
   * Get user profile
   */
  getUserData(infoData?) {
    return new Promise(async (resolve) => {
      try {
        if (this.variablesService.loginType === 'signup') {
          await this.pushDataSignup(this.response, infoData);
        } else {
          await this.pushDataLogin(this.response.data.user.entity, infoData);
        }
        this.authService.getUserProfile().subscribe(async (res: any) => {
          const user = this.authService.setLastOrderInfo(res.data);
          this.utilService.setUserData(user);
          // is Home v2
          let isanysip = false;
          if (res?.data?.listsubscriptions) {
            isanysip = this.utilService.checkForActiveSip(res?.data?.listsubscriptions);
          }
          let pathname;
          if (this.utilService.isBrowser) {
            pathname = window.location.pathname;
          }
          this.variablesService.homeV2.next(res && !isanysip && pathname === '/');
          if (this.neokred && !this.actRoute.snapshot.queryParams['onlyNgo']) {
            await this.callNeokredApi(user).then(val => {
              this.neokred = false;
            });
          }
          this.variablesService.userData.next(user);
          this.userData = user;
          this.loginEvent(this.userData);
          this.events.userGTMEvents({
            event: 'account',
            event_category: 'account',
            event_action: this.variablesService.loginType,
            event_label: user.entity_type
          });
          resolve('');
        });
      } catch (error) {
      }
    });
  }

  /**After login event */
  private loginEvent(user) {
    const claverTapData = {
      'Site': {
        'Identity': user.id,
        'Email': user.email,
        'Name': user.full_name,
        'Phone': user.extension + user.phone_1,
        'Mobile': user.extension + user.phone_1,
        'Full Name For URL': encodeURI(user.full_name),
        country_code: user?.extension || ''
      }
    };
    this.events.claverProfilePush(claverTapData);
  }

  public async afterLoginRedirects(value?) {
    this.dialog.closeAll();
    this.sheet.dismiss();
    const domain_url = this.utilService.domain_details.domain_url;
    const domain = environment.name === 'local' ? 'localhost' : '.ketto.org';
    /** Cookie to sign-in in microsite */
    this.cookieService.setCookie('u_auth', this.response?.data?.token, 365, domain);
    // this.cookieService.setCookie('u_auth', this.response?.data?.token, 365, 'infin.care');
    /** Code for start FR from DOCK starts */
    const dockFrData = this.storageService.getFromSession('dockFrData');
    this.storageService.deleteFromSession('dockFrData');
    this.storageService.deleteFromSession('dockCampaignerData');
    this.utilService.isLoggedIn.next(true);
    if ( value === 'gonetap' || this.noRedirectAfterLogin ) {
      this.noRedirectAfterLogin = false;
      return;
    }
    if (dockFrData && this.startFrType === 'medical') {
      const isDraftExist = await this.checkForDraft();
      if (isDraftExist) {
        this.router.navigate([`${this.startAFrUrls.medical}/advance`], {queryParamsHandling: 'merge'});
      } else {
        this.campaignService.createCampaign(dockFrData).subscribe(async (res: any) => {
          await this.systemEventsForDock(res.data);
          if (this.variablesService.loginType === 'signup') {
            await this.updateLeadData(this.response, res.data);
          }
          this.router.navigate([`${this.startAFrUrls.medical}/advance`], {
            queryParams: { type: 'medical', did: res.data.id },
            queryParamsHandling: 'merge'
          });
        });
        return;
      }
    }
    /** Code for start FR from DOCK ends */

    if (this.variablesService.loginType === 'signup') {
      const redirect_uri = this.actRoute.snapshot.queryParams['redirect_uri'];
      if (this.postLoginCB) { // If we have callbacks top priority is given to callbacks
        this.postLoginCB();
        this.postLoginCB = null;
        return true;
        // Nextly If we have redirect_uri in url then we give it 2nd priority
      } else if (redirect_uri && this.authService.verifyURI(redirect_uri)) {
        this.utilService.nativeNavigation(`${redirect_uri}?${this.router.url.split('?')[1]}`);
        return true;
      }
      const url = this.startFrType === 'medical' ?
        this.startAFrUrls.medical :
        this.startAFrUrls['non-medical'];

      // this.router.navigate([url], { queryParams: { type: this.startFrType }, queryParamsHandling: 'merge' });
    } else {
      this.actRoute.queryParams.pipe(first()).subscribe(params => {
        if (this.postLoginCB) { // If we have callbacks top priority is given to callbacks
          this.postLoginCB(this.userData);
          this.postLoginCB = null;
          // Nextly If we have redirect_uri in url then we give it 2nd priority
        } else if (params['redirect_uri'] && this.authService.verifyURI(params['redirect_uri'])) {
          // this.utilService.nativeNavigation(`${params['redirect_uri']}?${this.router.url.split('?')[1]}`);
          let navURL = params['redirect_uri'];
          const qParams = this.convertToQueryStr(params);
          if (qParams) {
            navURL += (navURL?.includes('?') ? '&' : '?') + qParams;
          }
          this.utilService.nativeNavigation(`${navURL}`);

        } else if (value === 'medibuddy') {
          return;
        } else if (typeof window !== 'undefined') { // Lastly fallbacks if none of the above two pararmeters are provided
          const signUpPage = ['new/crowdfunding'];
          const isSignUpPage = signUpPage.some(page => window.location.pathname.includes(page));
          if (isSignUpPage) { /**If login is from sign up page then move to SAC */
            const url = this.startFrType === 'medical' ?
              this.startAFrUrls.medical :
              this.startAFrUrls['non-medical'];
            this.router.navigate([url], { queryParams: { type: this.startFrType }, queryParamsHandling: 'merge' });
          } else if (this.userData && this.userData.entity_type === 'ngo') { // Redirect to NGO Dashboard
            this.utilService.nativeNavigation(domain_url +
              `/ngo/dashboard/ngo-dashboard.php#/profile/general? ${this.variablesService.loginType === 'signup' ? 'newsignup=1' : ''}`);
          } else if (this.userData && this.userData.entity_type === 'individual') {
            if (this.user_type === 'hospital') { // Check if logged in user has active campaigns
              // Redirect to Active Manage Campaign Dashboard
              const url = this.utilService.isMobile() ?
                `/campaign/manage_campaign.php` :
                `/manage/index.html#!/`;
              this.utilService.nativeNavigation(domain_url + url);
            } else if ((this.user_type === 'campaigner'
              && this.userData.all_active_campaigns
              && this.userData.all_active_campaigns.length > 0)) {
              this.utilService.nativeNavigation(environment.manage_domain + '/manage/list');

              /**
               * sipretention redirects
               *  if (this.userData?.listsubscriptions?.medical?.status_flag?.toString().match('1|2|4|5')) {
                    let sipRetentionUrl = environment.sipretention_domain;
                    if ([2, 4, 5].includes(this.userData?.listsubscriptions?.medical?.status_flag)) {
                      sipRetentionUrl += '/inactiveuser';
                    }
                    this.storageService.addSessionData('sipUserRedirect', true);
                    this.utilService.nativeNavigation(sipRetentionUrl);
                  } 
               * 
               * 
               */
            } else if (this.router.url.includes('/new/signin')) {
              this.utilService.navigate('/');  // Redirect to Homepage
              this.checkForDraft(); /** Check if draft exists */
            }
          } else {
            this.utilService.navigate('/');  // Redirect to Homepage
          }
        }
      });
    }
  }

  /**Check if user is logged in then redirect to start a FR else open sign up popup with sign up section */
  startFR(url?: string) {
    if (this.isUserLoggedIn === true) {
      this.router.navigate(['new/crowdfunding/medical'], {queryParamsHandling :'merge'});
    } else {
      this.router.navigate(['new/crowdfunding'], {queryParamsHandling :'merge'});
    }
  }

  openSignUp(template, activeScreen?: string, callback?) {
    const dialogRef = this.dialog.open(template, {
      maxWidth: '100vw',
      width: '730px',
      autoFocus: false,
      panelClass: ['login-popup'],
      data: {
        route: 'as',
        activeScreen: activeScreen || 'isu'
      }
    });
    if (callback) {
      this.postLoginCB = callback;
    }
    dialogRef.afterOpened().subscribe(() => this.isLoginPopUpOpen = true);
    dialogRef.afterClosed().subscribe(() => {
      this.isLoginPopUpOpen = false;
      this.isLeadGenerated = false;
    });
  }

  /**
   * Check whether there is draft for the logged in user
   */
  checkForDraft(openDialog = true): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        if (this.utilService.isBrowser) {
          const token = this.utilService.getUser()?.token;
          this.homepageService.getDraftData(token).pipe(take(1)).subscribe(
            (arg: any) => {
              const data = arg.data;
              this.dialog.closeAll();
              this.variablesService.draftData.next(data);
              this.draftData = data;
              if (data.parent_cause_id === 48) {
                this.variablesService.campaignPurpose = 'ngo';
              } else if (data.parent_cause_id === 20 && this.variablesService.validCausePM.includes(data.cause_id)) {
                this.variablesService.campaignPurpose = 'medical';
              } else if (data.parent_cause_id === 20 && !this.variablesService.validCausePM.includes(data.cause_id)) {
                this.variablesService.campaignPurpose = 'other';
              };
              if ((openDialog || data.status_flag !== 7 )&& !this.router.url.match('crowdfunding/fundraisers')) {
                this.dialog.open(CommonDraftFundraiserComponent, {
                  'maxWidth': '100vw',
                  width: data.status_flag === 7 ? '590px' : '500px',
                  panelClass: data.status_flag === 7 ? 'draftmodal' : 'underReviewModal',
                  disableClose: true, // Prevent all modal to be closed on outside click
                  data,
                  closeOnNavigation: true,
                });
              }
              resolve(true);
            },
            (err) => {
              resolve(false);
            }
          );
        } else {
          resolve(false);
        }
      } catch (error) {
      }
    });
  }



  generateLead(formValues, allowMultipleLeads?) {
    return new Promise<any>((resolve, reject) => {
      const payload = {
        about: `Start a fundraiser`,
        email: formValues.email,
        name: formValues.name,
        page: this.variablesService.loginType,
        phone: formValues.mobile,
        extension: formValues.extn,
        query_type: 'want_to_raise_funds',
        type: 'P - SAF Step 0',
        mail_to: this.variablesService.leadMailTo,
        subject: 'SAC signup-' + this.variablesService.leadSubject,
        cause: this.startFrType,
        city: this.utilService.getCityFromIp()
      };
      Object.assign(payload, this.utilService.getUTMs());
      this.homepageService.postLeadData(payload, true, allowMultipleLeads).subscribe(
        (next: any) => {
          const pageViewPayload = {
            eventName: 'campaign_lead_created',
            event_type: 'campaign',
            info_2: next.data.id,
            page_name: 'sac-signup',
            referrer_page: this.utilService.getReferrer()
          };
          this.events.sendSystemEvent(pageViewPayload).subscribe();
          this.isLeadGenerated = true;
          resolve(next);
        },
        (err) => {
          resolve(err);
        }
      );
    });
  }

  /** Tracking for dock Start fr */
  systemEventsForDock(data) {
    return new Promise((resolve, reject) => {
      try {
        const payload1 = {
          eventName: 'start_a_campaign_step1_completed',
          event_type: 'campaign',
          page_name: 'Start a Campaign step1',
          info_1: 'type',
          info_2: 'personal',
          event_type_id: data.id
        };
        this.events.sendSystemEvent(payload1).subscribe(
          next => { resolve(true); },
          error => { resolve(false); }
        );
      } catch (error) {
      }
    });
  }
  /** Update lead for dock Start fr */
  updateLeadData(userData, data) {
    return new Promise((resolve) => {
      try {
        const ku = this.cookieService.getCookie('_ku');
        const user = userData.data.user.entity;
        const payload = {
          about: `Start a fundraiser`,
          email: user.email,
          name: user.full_name,
          page: this.variablesService.pageName,
          phone: user.phone_1,
          extension: user.extension,
          query_type: 'want_to_raise_funds',
          type: 'ketto-campaign-lead-dock',
          manager_id: data.manager_id,
          user_id: `${ku}@ketto.org`,
          status: 1,
          campaign_id: data.id
        };
        this.homepageService.updateLeadData(payload).subscribe(
          next => {
            resolve('leadUpdated');
            this.isLeadGenerated = true;
          },
          err => {
            console.log(err);
            resolve('lead not updated');
          }
        );
      } catch (error) {
      }
    });
  }

  convertToQueryStr(params) {
    return Object.keys(params).filter(key => key !== 'redirect_uri')
      .map(key => `${key}=${params[key]}`)
      .join('&');
  }

  sendLoginSystemEvents(eventName, info?: {info_1?: string, info_2?: string, info_3?: string}) {
    const payload = {
      eventName,
      event_type: 'signin',
      page_name: this.variablesService.pageName || 'home',
      referrer_page: this.document.referrer
    }
    if (info?.info_1) { payload['info_1'] = info.info_1; }
    if (info?.info_2) { payload['info_2'] = info.info_2; }
    if (info?.info_3) { payload['info_3'] = info.info_3; }
    this.events.sendSystemEvent(payload).subscribe(_ => _);
  }

  callNeokredApi(user: IUser): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        // if user has phone number && if user doesnt have address or pan number
        if (user?.phone_1 && (!user?.address_1 || !user?.pan_number)) {
          const params = { otp: this.otp };
          this.apiService.post(environment.APP.API_URLS.NEOKRED_API(user?.id), params, 'false')
            .subscribe({next : val => {
              resolve('');
            }, error: error => {
              resolve('');
            }});
        } else {
          resolve('');
        }
      } catch (error) {
        reject(false);
      }
    });
  }

  draftRedirection() {
    const startFrType = this.variablesService.campaignPurpose !== 'medical' ? 'non-medical': 'medical';
    // when draft is not approved
    if (this.draftData.status_flag !==1) {
      this.router.navigate(
        [this.startAFrUrls[startFrType]],
        { queryParams: { did: this.draftData.id }, queryParamsHandling: 'merge' }
      );
    } 
  }
}
