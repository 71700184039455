import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService, UtilService } from './../../services/index';

@Component({
  selector: 'app-login-via-token',
  templateUrl: './login-via-token.component.html',
  styleUrls: ['./login-via-token.component.scss']
})
export class LoginViaTokenComponent implements OnInit {

  loginPurpose = '';

  constructor(
    private storageService: StorageService,
    private utilService: UtilService,
    private actRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginPurpose = this.actRoute.snapshot.queryParams['type'];
    this.login();
  }

  login() {
    /** Get token from url */
    const TOKEN = this.actRoute.snapshot.queryParams['token'];
    /** Add token to local storage so that interceptor can pass token with api */
    this.utilService.setUser({ token: TOKEN });
    /** Hit me api to get user details */
    this.authService.getUserProfile().subscribe(
      (data: any) => {
        if (!data.error) {
          this.redirectAfterLogin(TOKEN, data);
        }
      },
      (error) => {
        /** Delete user details from local storage */
        this.storageService.delete('user');
        /** If api returns error redirect to sign in page */
        this.utilService.navigate('/new/signin');
      }
    );
  }


  private redirectAfterLogin(TOKEN: any, data: any) {
    const redirect_uri = this.actRoute.snapshot.queryParams['redirect_uri'];
    /** Update local storage with proper values */
    this.utilService.setUser({ token: TOKEN, user: data.data });
    this.utilService.setUserData(data.data);
    if (redirect_uri && this.authService.verifyURI(redirect_uri)) {
      /** Redirect to redirect uri (if provided) */
      this.utilService.nativeNavigation(decodeURIComponent(redirect_uri));
    } else {
      this.utilService.navigate('/');
    }
  }
}
